.App {
  text-align: center;
}

body {
  color: #89ff52;
  font-size: 17px;
}

nav {
padding-top:30vh;
}

.background {
  background-image: url(Background.gif);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.info {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:20px;
}

.link {
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}

a:link {
  font-family: "consolas";
  color:#89ff52;
  text-decoration: none;
}

a:active {
  font-family: "consolas";
  color:#89ff52;
  text-decoration: none;
}

a:visited {
  font-family: "consolas";
  color:#89ff52;
  text-decoration: none;
}


a:hover {
  font-family: "consolas";
  color:#ff0000;
  text-decoration: none;
}

.image {
  height: 70px;
}

.music {
  display: flex;
  align-items: center;
  justify-content: center;

}

@media only screen and (max-width: 600px) {
  body {
    font-size: 10px;
  }
  .image {
    height:50px
  }
}


@media screen and (orientation:landscape)
and (min-device-width: 319px)
and (max-device-width: 480px) {
  body{

  }
  .image{
    height: 5px;
  }
}
